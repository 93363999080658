import React, { useState } from 'react';
import { Box, VStack } from '@chakra-ui/react';
import CustomersTopMetrics from './customers/CustomersTopMetrics';
import DetailedChart from './shared/DetailedChart';

const CustomersSection = ({ auditData, reportData }) => {
  const [showCustomerGrowth, setShowCustomerGrowth] = useState(true);
  const [showPayingCustomers, setShowPayingCustomers] = useState(true);

  // Transform auditData for paying customers into a format compatible with DetailedChart
  const payingCustomersHistory = [
    {
      date: new Date().toISOString().split('T')[0], // Current month
      payingCustomers: auditData.payingCustomersLastMonths?.lastMonthCustomers || 0,
    },
    {
      date: new Date(new Date().setMonth(new Date().getMonth() - 3))
        .toISOString()
        .split('T')[0], // Three months ago
      payingCustomers: auditData.payingCustomersLastMonths?.threeMonthsAgoCustomers || 0,
    },
  ];

  // Combine paying customers into reportData's timeSeriesData
  const combinedTimeSeriesData = {
    ...reportData?.timeSeriesData,
    payingCustomerHistory: payingCustomersHistory,
  };

  const customersDatasetsConfig = [
    {
      label: 'Customer Growth',
      dataKey: 'customerHistory',
      valueKey: 'customers', // Use "customers" field from data points
      color: '54, 162, 235', // RGB color
      yAxisID: 'y-axis-numbers', // Ensure it uses the numbers axis
      showToggle: showCustomerGrowth,
      setShowToggle: setShowCustomerGrowth,
      switchColorScheme: 'blue',
    },
    {
      label: 'Paying Customers',
      dataKey: 'payingCustomerHistory',
      valueKey: 'payingCustomers', // Use "payingCustomers" field from data points
      color: '255, 99, 132',
      yAxisID: 'y-axis-numbers', // Ensure it uses the numbers axis
      showToggle: showPayingCustomers,
      setShowToggle: setShowPayingCustomers,
      switchColorScheme: 'red',
    },
  ];

  return (
    <VStack spacing={0} align="stretch" mb={6}>
      {/* Top Metrics Section */}
      <Box>
        <CustomersTopMetrics auditData={auditData} reportData={reportData} />
      </Box>

      {/* Growth Chart Section */}
      <Box>
        <DetailedChart
          datasetsConfig={customersDatasetsConfig}
          reportData={{ timeSeriesData: combinedTimeSeriesData }}
          foundingDate={auditData.foundingDate}
        />
      </Box>
    </VStack>
  );
};

export default CustomersSection;
