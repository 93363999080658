import React, { useEffect, useState } from 'react';
import { Grid, Box, Text, Tooltip, VStack, Icon } from '@chakra-ui/react';
import { FaSmile, FaFrown } from 'react-icons/fa';

const GoodBadAnalysis = ({ reportData, section }) => {
  const [sectionData, setSectionData] = useState(null);

  useEffect(() => {
    // Retrieve from local storage if reportData is not passed as a prop 
    if (!reportData) {
      const storedData = JSON.parse(localStorage.getItem('reportData'));
      if (storedData) {
        setSectionData(storedData.chatGPTResponses?.[section]);
      }
    } else {
      setSectionData(reportData.chatGPTResponses?.[section]);
    }
  }, [reportData, section]);

  return (
    <Box 
      mb={6} 
      bg="rgba(28, 35, 54, 1)" 
      p={{ base: 4, md: 6 }} // Consistent padding
      borderRadius="lg" 
      mt={6} 
      maxW="100%"
    >
      {/* Header Box */}
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        mb={6} 
        mt={2} // Add spacing above header for consistency
      >
        <Tooltip
          label="An overview of the key strengths and weaknesses in this section, helping to highlight areas of advantage and areas for improvement." 
          aria-label="Strengths & Weaknesses tooltip"
        >
          <Text 
            color="gray.300" 
            fontSize="lg" 
            fontWeight="bold" 
            cursor="help"
          >
            Strengths & Weaknesses
          </Text>
        </Tooltip>
      </Box>

      {/* Layout adjusts for mobile (single column) and desktop (two columns) */}
      <Grid 
        templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} 
        gap={8} 
      >
        {/* Strengths Section */}
        <VStack align="center" spacing={4}>
          <Icon as={FaSmile} boxSize={12} color="green.400" mb={2} />
          <Grid templateColumns="1fr" gap={4} width="100%">
            {sectionData?.goodPoints?.map((point, index) => (
              <Box 
                key={index} 
                p={6} 
                bg="transparent" 
                borderRadius="md" 
                border="2px solid" 
                borderColor="rgba(74, 222, 128, 0.7)"
              >
                <Text color="white">{point}</Text>
              </Box>
            ))}
          </Grid>
        </VStack>

        {/* Weaknesses Section */}
        <VStack align="center" spacing={4}>
          <Icon as={FaFrown} boxSize={12} color="red.400" mb={2} />
          <Grid templateColumns="1fr" gap={4} width="100%">
            {sectionData?.badPoints?.map((point, index) => (
              <Box 
                key={index} 
                p={4} 
                bg="transparent" 
                borderRadius="md" 
                border="2px solid" 
                borderColor="rgba(248, 113, 113, 0.7)"
              >
                <Text color="white">{point}</Text>
              </Box>
            ))}
          </Grid>
        </VStack>
      </Grid>
    </Box>
  );
};

export default GoodBadAnalysis;
