import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Switch,
  Grid,
  ButtonGroup,
  Button,
  Collapse,
  IconButton,
  Divider,
  useBreakpointValue,
} from '@chakra-ui/react';
import { SettingsIcon } from '@chakra-ui/icons';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import 'chartjs-adapter-date-fns';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, TimeScale, Filler, Tooltip, Legend);

const DetailedChart = ({ reportData, datasetsConfig }) => {
  const [chartData, setChartData] = useState(null);
  const [timeRange, setTimeRange] = useState('ALL');
  const [availableRanges, setAvailableRanges] = useState([]);
  const [showSettings, setShowSettings] = useState(false);

  const [toggles, setToggles] = useState(() =>
    datasetsConfig.reduce((acc, { label }) => {
      acc[label] = true;
      return acc;
    }, {})
  );

  const columns = useBreakpointValue({ base: 'repeat(2, 1fr)', md: 'repeat(6, 1fr)' });

  const auditData = JSON.parse(localStorage.getItem('auditData')) || {};
  const foundingDate = auditData?.foundingDate || '2021-06';

  useEffect(() => {
    if (!foundingDate) return;

    const currentYear = new Date().getFullYear();
    const foundingYear = parseInt(foundingDate.split('-')[0], 10);
    const yearsSinceFounding = currentYear - foundingYear;

    const ranges = ['3M', '6M', 'YTD'];
    if (yearsSinceFounding >= 1) ranges.push('1Y');
    if (yearsSinceFounding >= 2) ranges.push('2Y');
    if (yearsSinceFounding >= 5) ranges.push('5Y');
    ranges.push('ALL');

    setAvailableRanges(ranges);
  }, [foundingDate]);

  const getStartDateForRange = () => {
    const today = new Date();
    let startDate;

    switch (timeRange) {
      case '3M':
        startDate = new Date(today.setMonth(today.getMonth() - 3));
        break;
      case '6M':
        startDate = new Date(today.setMonth(today.getMonth() - 6));
        break;
      case 'YTD':
        startDate = new Date(today.getFullYear(), 0, 1);
        break;
      case '1Y':
        startDate = new Date(today.setFullYear(today.getFullYear() - 1));
        break;
      case '2Y':
        startDate = new Date(today.setFullYear(today.getFullYear() - 2));
        break;
      case '5Y':
        startDate = new Date(today.setFullYear(today.getFullYear() - 5));
        break;
      case 'ALL':
        startDate = new Date(foundingDate);
        break;
      default:
        startDate = new Date(foundingDate);
    }

    const foundingDateObj = new Date(foundingDate);
    return startDate < foundingDateObj ? foundingDateObj : startDate;
  };

  const calculateCashDataset = (burnRate, initialCash, lastRealDataPoint) => {
    const cashData = [];
    let remainingCash = initialCash;
    let currentDate = new Date(lastRealDataPoint?.x);

    while (remainingCash > 0) {
      cashData.push({ x: currentDate.toISOString().slice(0, 10), y: remainingCash });
      currentDate.setMonth(currentDate.getMonth() + 1);
      remainingCash = Math.max(remainingCash - burnRate, 0);
    }

    return cashData;
  };

  const createSolidLineGradient = (ctx, chartArea, color) => {
    if (!chartArea) return `rgba(${color}, 0)`;
    const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
    gradient.addColorStop(0, `rgba(${color}, 0.3)`);
    gradient.addColorStop(1, `rgba(${color}, 0)`);
    return gradient;
  };

  const createSegmentedDataset = (label, data, color, yAxisID) => {
    const lastSolidPointIndex = data.findIndex((point) => point.y !== 0);
    return {
      label,
      data,
      borderColor: `rgba(${color}, 1)`,
      borderWidth: 2,
      pointRadius: 0,
      pointHoverBorderWidth: 3,
      pointHoverRadius: 10,
      hitRadius: 10,
      tension: 0.2,
      spanGaps: true,
      yAxisID,
      segment: {
        borderDash: (ctx) =>
          ctx.p0DataIndex < lastSolidPointIndex ? [5, 5] : [],
        borderColor: (ctx) =>
          ctx.p0DataIndex < lastSolidPointIndex
            ? 'rgba(128, 128, 128, 1)'
            : `rgba(${color}, 1)`,
        backgroundColor: (ctx) =>
          ctx.p0DataIndex >= lastSolidPointIndex
            ? createSolidLineGradient(ctx.chart.ctx, ctx.chart.chartArea, color)
            : 'transparent',
      },
      fill: true,
    };
  };

  useEffect(() => {
    if (reportData?.timeSeriesData) {
      const startDate = getStartDateForRange();
      const datasets = [];

      datasetsConfig.forEach(({ label, dataKey, valueKey, color, yAxisID }) => {
        if (!toggles[label]) return;

        if (label === 'Cash') {
          const burnHistory = reportData.timeSeriesData.burnHistory || [];
          const lastRealDataPoint = burnHistory[burnHistory.length - 1] || {};
          const burnRate = Math.abs(lastRealDataPoint?.burn || 0);
          const initialCash = (auditData?.runway || 0) * burnRate;

          if (burnRate > 0 && initialCash > 0 && lastRealDataPoint?.date) {
            const cashData = calculateCashDataset(burnRate, initialCash, {
              x: lastRealDataPoint.date,
              y: initialCash,
            });
            datasets.push(createSegmentedDataset(label, cashData, color, yAxisID));
          }
        } else {
          const filteredData = reportData.timeSeriesData[dataKey]
            ?.filter((point) => new Date(point.date) >= startDate)
            .map((point) => ({
              x: point.date,
              y: dataKey === 'burnHistory' ? Math.abs(point[valueKey]) : point[valueKey],
            }));
          datasets.push(createSegmentedDataset(label, filteredData || [], color, yAxisID));
        }
      });

      setChartData({ datasets });
    }
  }, [reportData, datasetsConfig, timeRange, toggles]);

  const secondaryAxisUsed = chartData?.datasets.some((ds) => ds.yAxisID === 'y-axis-numbers');

  const options = {
    responsive: true,
    plugins: {
      tooltip: { mode: 'index', intersect: false },
      legend: { display: false },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: {
            '3M': 'week',
            '6M': 'week',
            'YTD': 'month',
            '1Y': 'month',
            '2Y': 'month',
            '5Y': 'year',
            'ALL': 'year',
          }[timeRange] || 'month',
          displayFormats: {
            day: 'MMM dd',
            week: 'MMM dd',
            month: 'yyyy-MM',
            year: 'yyyy',
          },
          tooltipFormat: 'yyyy-MM-dd',
        },
        min: getStartDateForRange(),
        grid: { color: 'rgba(75, 85, 99, 0.3)' },
        ticks: {
          color: '#D1D5DB',
          autoSkip: true,
          maxTicksLimit: 12,
        },
      },
      'y-axis-monetary': {
        type: 'linear',
        position: 'left',
        ticks: {
          color: '#D1D5DB',
          callback: (value) =>
            value >= 1000000
              ? `$${Math.round(value / 1000000)}M`
              : `$${Math.round(value / 1000)}K`,
        },
        grid: { color: 'rgba(75, 85, 99, 0.3)' },
        beginAtZero: true,
      },
      ...(secondaryAxisUsed && {
        'y-axis-numbers': {
          type: 'linear',
          position: 'right',
          ticks: {
            color: '#D1D5DB',
            beginAtZero: true,
          },
          grid: { drawOnChartArea: false },
          min: 0,
        },
      }),
    },
  };

  if (!chartData) return <Text color="red.500">No data available.</Text>;

  return (
    <Box mb={12} p={4} bg="rgba(15, 23, 42, 0.4)" borderRadius="md" mt={8}>
      <Flex direction="column" mb={4}>
        <Flex align="center" justify="space-between" mb={2}>
          <IconButton
            icon={<SettingsIcon />}
            onClick={() => setShowSettings(!showSettings)}
            variant="ghost"
            size="lg"
            color="gray.400"
            _hover={{ color: 'white' }}
            aria-label="Toggle settings"
          />
          <Flex overflowX="auto" whiteSpace="nowrap" alignItems="center" gap={2}>
            <ButtonGroup size="sm">
              {availableRanges.map((range) => (
                <Button
                  key={range}
                  variant="unstyled"
                  color={timeRange === range ? 'white' : 'gray.400'}
                  fontWeight={timeRange === range ? 'bold' : 'normal'}
                  onClick={() => setTimeRange(range)}
                  _hover={{ color: 'white' }}
                  _focus={{ boxShadow: 'none' }}
                  bg={timeRange === range ? 'gray.600' : 'transparent'}
                  borderRadius="md"
                  px={3}
                >
                  {range}
                </Button>
              ))}
            </ButtonGroup>
          </Flex>
        </Flex>
        {showSettings && <Divider orientation="horizontal" borderColor="gray.600" mb={4} />}
        <Collapse in={showSettings} animateOpacity>
          <Grid templateColumns={columns} gap={4}>
            {datasetsConfig.map(({ label, switchColorScheme }) => (
              <Flex align="center" key={label}>
                <Text mr={2} color="gray.300" fontSize="sm">
                  {label}
                </Text>
                <Switch
                  isChecked={toggles[label]}
                  onChange={() =>
                    setToggles((prev) => ({
                      ...prev,
                      [label]: !prev[label],
                    }))
                  }
                  colorScheme={switchColorScheme}
                />
              </Flex>
            ))}
          </Grid>
        </Collapse>
      </Flex>
      <Line data={chartData} options={options} />
    </Box>
  );
};

export default DetailedChart;
