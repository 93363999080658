import React, { useState, useEffect } from 'react';
import { 
  IconButton, Tooltip, useToast, Menu, MenuButton, MenuItem, 
  MenuList, Text, Divider, Box, Flex, Portal 
} from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { API_URL } from '../../../constants';
import { keyframes } from '@emotion/react';
import { ClipboardDocumentCheckIcon, ChartBarIcon } from '@heroicons/react/24/outline';
import { HiOutlineScale } from 'react-icons/hi2';

const fadeInOut = keyframes`
  0%, 100% { opacity: 0.3; }
  50% { opacity: 1; }
`;

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const fadeInGreen = keyframes`
  0%, 100% { opacity: 0.3; }
  50% { opacity: 1; }
`;

const GenerateAnalysisButton = ({ reportId, onUpdateReport }) => {
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const getReportAnalysisStatus = () => localStorage.getItem('reportAnalysis');

  const isAnalysisPending = getReportAnalysisStatus() === 'pending';
  const isAnalysisProcessing = getReportAnalysisStatus() === 'processing';
  const isAnalysisCompleted = getReportAnalysisStatus() === 'completed';

  useEffect(() => {
    if (isAnalysisProcessing) {
      setLoading(true);
    }
  }, [isAnalysisProcessing]);

  const handleCompleteAnalysis = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');

    try {
      // Set reportAnalysis to "processing" in localStorage
      localStorage.setItem('reportAnalysis', 'processing');

      // Trigger full analysis for all sections
      const response = await axios.post(
        `${API_URL}/v2/reports/generate-analysis`,
        { reportId, section: "full" },
        {
          headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
          timeout: 120000, // 2 minutes
        }
      );      

      toast({
        title: "Full Report Analysis triggered successfully.",
        description: response.data.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      if (onUpdateReport) {
        await onUpdateReport();
      }

      // Set reportAnalysis to "completed" in localStorage
      localStorage.setItem('reportAnalysis', 'completed');
    } catch (error) {
      toast({
        title: "Error triggering full report analysis.",
        description: error.response?.data?.error || "An unexpected error occurred.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      // Revert status to "pending" if an error occurs
      localStorage.setItem('reportAnalysis', 'pending');
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateAnalysis = async (section) => {
    setLoading(true);
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post(
        `${API_URL}/v2/reports/generate-analysis`,
        { reportId, section },
        { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' } }
      );

      toast({
        title: `${section} Analysis generated successfully.`,
        description: response.data.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      if (onUpdateReport) {
        await onUpdateReport();
      }
    } catch (error) {
      toast({
        title: `Error generating ${section} analysis.`,
        description: error.response?.data?.error || "An unexpected error occurred.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleRecalculateAudit = async () => {
    setLoading(true);
    const auditEndpoint = '/v2/audits/recalculate';
  
    try {
      const token = localStorage.getItem('token');
      const auditId = localStorage.getItem('auditId');

      if (!auditId) {
        toast({
          title: "Audit ID not found.",
          description: "Please ensure an audit is created first.",
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
        setLoading(false);
        return;
      }
  
      const response = await axios.post(
        `${API_URL}${auditEndpoint}`,
        { auditId },
        { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' } }
      );
  
      toast({
        title: "Audit recalculated successfully.",
        description: response.data.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      if (onUpdateReport) {
        await onUpdateReport();
      }
    } catch (error) {
      toast({
        title: "Error recalculating audit.",
        description: error.response?.data?.error || "An unexpected error occurred.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
<Menu
  onOpen={() => setIsMenuOpen(true)} // Track menu open state
  onClose={() => setIsMenuOpen(false)} // Track menu close state
>
  <Tooltip label="Generate New Analysis or Recalculate Audit" aria-label="Generate analysis tooltip">
    <MenuButton
    as={IconButton}
    icon={
      <HiOutlineScale
        sx={{
          color: isMenuOpen ? 'white' : loading ? 'blue.400' : isAnalysisCompleted ? 'gray.400' : 'green.400',
          animation: loading ? `${spin} 1s linear infinite` : undefined, // Spinning effect when loading
        }}
      />
    }
    aria-label="Generate Analysis"
    variant="ghost"
    sx={{
      bg: isMenuOpen ? 'rgba(15, 23, 42, 0.9)' : 'transparent', // Match menu background color when open
      color: isMenuOpen ? 'white' : 'gray.400', // Icon color white when active
      _hover: {
        bg: isMenuOpen ? 'rgba(15, 23, 42, 0.9)' : 'rgba(34, 34, 34, 0.8)', // Keep same when active
        color: 'white',
      },
      _active: {
        bg: 'rgba(15, 23, 42, 0.9)', // Match menu background color when clicked
        color: 'white',
      },
    }}
    fontSize="md"
    borderRadius="md"
    padding="4px"
  />
  </Tooltip>
      <Portal>
        <MenuList
          bg="rgba(15, 23, 42)"
          borderColor="#475569"
          boxShadow="lg"
          zIndex="9999"
          width="320px" // Updated width to match the wider menu
          justify="space-between"
          px={0} // Added horizontal padding for consistency
          py={3} // Added vertical padding for better spacing
        >
          <MenuItem
            onClick={handleRecalculateAudit}
            bg="rgba(15, 23, 42, 0.9)"
            color="#cbd5e1"
            _hover={{ bg: '#334155', color: 'white' }}
            py={3}
            isDisabled={false}
          >
            <Flex alignItems="center">
              <ClipboardDocumentCheckIcon className="h-5 w-5 mr-2" />
              <Box>
                <Text fontWeight="bold">Audit Analysis</Text>
                <Text fontSize="xs" color="gray.400">Recalculate the baseline audit metrics.</Text>
              </Box>
            </Flex>
          </MenuItem>

          <Divider borderColor="gray.500" my={1} />

          <MenuItem
            onClick={handleCompleteAnalysis}
            bg={!isAnalysisCompleted && !isAnalysisProcessing ? 'rgba(34, 197, 94, 0.2)' : 'rgba(15, 23, 42, 0.9)'}
            color="gray.200"
            _hover={{ bg: '#334155', color: 'white' }}
            py={3}
            isDisabled={loading || isAnalysisProcessing}
            position="relative"
            overflow="hidden"
          >
            {!isAnalysisCompleted && !isAnalysisProcessing && (
              <Box
                position="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
                bg="rgba(34, 197, 94, 0.2)"
                animation={`${fadeInGreen} 3s ease-in-out infinite`}
                zIndex="-1"
              />
            )}
            <Flex alignItems="center">
              <ChartBarIcon className="h-5 w-5 mr-2" />
              <Box>
                <Text fontWeight="bold">Full Report Analysis</Text>
                <Text fontSize="xs" color="gray.400">
                  Run a comprehensive analysis across all sections.
                </Text>
              </Box>
            </Flex>
          </MenuItem>

          <Divider borderColor="gray.500" my={1} />

          <Text fontWeight="bold" color="gray.500" fontSize="xs" textAlign="left" px={3} mb={1} mt={2}>
            Report Section Analysis:
          </Text>

          {[
            { label: 'Startup Analysis', value: 'startupDescription' },
            { label: 'Investment Analysis', value: 'investment' },
            { label: 'Financials Analysis', value: 'financials' },
            { label: 'Market Analysis', value: 'market' },
            { label: 'Customers Analysis', value: 'customers' },
            { label: 'Product Analysis', value: 'product' },
            { label: 'Team Analysis', value: 'team' },
          ].map((item, index) => (
            <MenuItem
              key={index}
              onClick={() => handleGenerateAnalysis(item.value)}
              bg="rgba(15, 23, 42, 0.9)"
              fontWeight="bold"
              color="#cbd5e1"
              _hover={{ bg: '#334155', color: 'white' }}
              py={3}
              isDisabled={!isAnalysisCompleted || loading}
            >
              {item.label}
            </MenuItem>
          ))}
        </MenuList>
      </Portal>
      {loading && (
        <Box ml={0}>
          <Text
            mr={2}
            color="blue.400"
            fontSize="sm"
            animation={`${fadeInOut} 2s ease-in-out infinite`}
          >
            Analyzing
          </Text>
        </Box>
      )}
    </Menu>
  );
};

export default GenerateAnalysisButton;
