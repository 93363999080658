import React, { useState } from 'react';
import { Box, VStack } from '@chakra-ui/react';
import FinancialsTopMetrics from './financials/FinancialsTopMetrics';
import DetailedChart from './shared/DetailedChart';

const calculateCashDataset = (burnRate, initialCash, lastRealDataPoint) => {
  const cashData = [];
  let remainingCash = initialCash;
  let currentDate = new Date(lastRealDataPoint?.x);

  while (remainingCash > 0) {
    cashData.push({ x: currentDate.toISOString().slice(0, 10), y: remainingCash });
    currentDate.setMonth(currentDate.getMonth() + 1);
    remainingCash = Math.max(remainingCash - burnRate, 0);
  }

  return cashData;
};

const FinancialsSection = ({ auditData, reportData }) => {
  const [showRevenue, setShowRevenue] = useState(true);
  const [showCost, setShowCost] = useState(true);
  const [showBurn, setShowBurn] = useState(false);
  const [showCash, setShowCash] = useState(false);

  const financialsDatasetsConfig = [
    {
      label: 'Revenue',
      dataKey: 'revenueHistory',
      valueKey: 'revenue',
      color: '75, 192, 192',
      yAxisID: 'y-axis-monetary',
      showToggle: showRevenue,
      setShowToggle: setShowRevenue,
      switchColorScheme: 'teal',
    },
    {
      label: 'Cost',
      dataKey: 'costHistory',
      valueKey: 'cost',
      color: '255, 206, 86',
      yAxisID: 'y-axis-monetary',
      showToggle: showCost,
      setShowToggle: setShowCost,
      switchColorScheme: 'yellow',
    },
    {
      label: 'Burn',
      dataKey: 'burnHistory',
      valueKey: 'burn',
      color: '255, 99, 132',
      yAxisID: 'y-axis-monetary',
      showToggle: showBurn,
      setShowToggle: setShowBurn,
      switchColorScheme: 'red',
      preprocessData: (data) => data.map((entry) => ({ ...entry, burn: Math.abs(entry.burn) })),
    },
    {
      label: 'Cash',
      dataKey: 'burnHistory', // Cash uses burn data for calculations
      customData: ({ dataKey }) => {
        const burnHistory = reportData?.timeSeriesData?.[dataKey] || [];
        const lastRealDataPoint = burnHistory[burnHistory.length - 1] || {};
        const burnRate = Math.abs(lastRealDataPoint?.burn || 0);
        const initialCash = (auditData?.runway || 0) * burnRate;

        if (burnRate > 0 && initialCash > 0 && lastRealDataPoint?.date) {
          return calculateCashDataset(burnRate, initialCash, {
            x: lastRealDataPoint.date,
            y: initialCash,
          });
        }
        return [];
      },
      color: '54, 162, 235',
      yAxisID: 'y-axis-monetary',
      showToggle: showCash,
      setShowToggle: setShowCash,
      switchColorScheme: 'cyan',
    },
  ];

  const processedReportData = {
    ...reportData,
    timeSeriesData: {
      ...reportData?.timeSeriesData,
      burnHistory: financialsDatasetsConfig
        .find((config) => config.label === 'Burn')
        ?.preprocessData(reportData?.timeSeriesData?.burnHistory || []),
      cashHistory: financialsDatasetsConfig
        .find((config) => config.label === 'Cash')
        ?.customData({ dataKey: 'burnHistory' }),
    },
  };

  return (
    <VStack spacing={0} align="stretch" mb={6}>
      {/* Top Metrics Section */}
      <Box>
        <FinancialsTopMetrics auditData={auditData} reportData={reportData} />
      </Box>

      {/* Growth Chart Section */}
      <Box>
        <DetailedChart
          datasetsConfig={financialsDatasetsConfig}
          reportData={processedReportData}
          foundingDate={auditData.foundingDate}
        />
      </Box>
    </VStack>
  );
};

export default FinancialsSection;
