import React, { useState } from 'react';
import { Box, VStack } from '@chakra-ui/react';
import MarketTopMetrics from './market/MarketTopMetrics';
import DetailedChart from './shared/DetailedChart';

const MarketSection = ({ auditData, reportData }) => {
  const [showCustomers, setShowCustomers] = useState(true);

  const marketDatasetsConfig = [
    {
      label: 'Customers',
      dataKey: 'customerHistory',
      valueKey: 'customers', // Ensure the correct key for extracting values
      color: '54, 162, 235', // Only RGB, alpha is handled in the chart
      yAxisID: 'y-axis-numbers',
      showToggle: showCustomers,
      setShowToggle: setShowCustomers,
      switchColorScheme: 'blue',
    },
  ];

  return (
    <VStack spacing={0} align="stretch" mb={6}>
      {/* Top Metrics Section */}
      <Box>
        <MarketTopMetrics auditData={auditData} reportData={reportData} />
      </Box>

      {/* Growth Chart Section */}
      <Box>
        <DetailedChart
          datasetsConfig={marketDatasetsConfig}
          reportData={reportData}
        />
      </Box>
    </VStack>
  );
};

export default MarketSection;
