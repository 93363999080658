import React, { useMemo } from 'react';
import { SimpleGrid, Box, Stat, StatLabel, StatNumber } from '@chakra-ui/react';
import InvestmentGoalMetric from './topMetrics/InvestmentGoalMetric';
import InvestabilityMetric from './topMetrics/InvestabilityMetric';
import RunwayMetric from './topMetrics/RunwayMetric';
import InvestmentStageMetric from './topMetrics/InvestmentStageMetric';
import ValuationMetric from './topMetrics/ValuationMetric';

const TopMetrics = () => {
  // Retrieve auditData and reportData from local storage
  const auditData = useMemo(() => JSON.parse(localStorage.getItem('auditData')), []);
  const reportData = useMemo(() => JSON.parse(localStorage.getItem('reportData')), []);

  console.log('Audit Data:', auditData);  // Debugging log
  console.log('Report Data:', reportData);  // Debugging log

  // Extract audit metrics with useMemo to prevent recalculating on every render
  const auditMetrics = useMemo(() => ({
    goalAmount: auditData?.fundingGoal || 0,  // Funding goal
    raisedAmount: auditData?.currentFunding || 0,  // Amount raised
    monthsLeft: auditData?.runway || 0,  // Runway in months
    revenue: auditData?.revenueLastMonths?.lastMonthRevenue || 0,  // Revenue from last month
    cost: auditData?.monthlyCost?.lastMonthCost || 0,  // Cost from last month
  }), [auditData]);

  // Extract report metrics with useMemo
  const reportMetrics = useMemo(() => ({
    investabilityScore: reportData?.overallScore?.toFixed(1) || 0,  // Overall score
    currentValuation: reportData?.financialMetrics?.currentValuation || 0,  // Current valuation
    futureValuation: reportData?.financialMetrics?.futureValuation || 0,  // Future valuation
    burnRate: reportData?.financialMetrics?.burnRate || 0,  // Burn rate
  }), [reportData]);

  return (
    <SimpleGrid columns={[1, 2, 3, 4, 5]} spacing={6}>
      {/* Investability Metric */}
      <InvestabilityMetric score={reportMetrics.investabilityScore} />

      {/* Investment Goal Metric (Goal vs Raised) */}
      <InvestmentGoalMetric
        goal={auditMetrics.goalAmount}
        raised={auditMetrics.raisedAmount}
        revenue={auditMetrics.revenue}
        cost={auditMetrics.cost}
      />

      {/* Runway Metric */}
      <RunwayMetric
        runwayMonths={auditMetrics.monthsLeft}
        burn={reportMetrics.burnRate}
      />

      {/* Investment Stage Realism Metric */}
      <InvestmentStageMetric
        stageScore={reportMetrics.stageScore}
        investmentStage={reportMetrics.investmentStage}
      />

      {/* Valuation Metric */}
      <ValuationMetric
        currentValuation={reportMetrics.currentValuation}
        futureValuation={reportMetrics.futureValuation}
      />
    </SimpleGrid>
  );
};

export default TopMetrics;
