import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Text,
  Flex,
  Button,
  SimpleGrid,
  CircularProgress,
  CircularProgressLabel,
  Divider,
} from "@chakra-ui/react";

const ViewAdvisorAnalysis = ({ advisorType, isOpen, onClose }) => {
  const [insights, setInsights] = useState(null);

  useEffect(() => {
    // Fetch insights from localStorage
    const advisorAnalysisData =
      JSON.parse(localStorage.getItem("advisorAnalysisData")) || [];
    const advisorInsights = advisorAnalysisData.find(
      (analysis) => analysis.advisorType === advisorType
    );
    setInsights(advisorInsights || {});
  }, [advisorType]);

  const AnimatedCircularGauge = ({ score, label }) => {
    const [displayScore, setDisplayScore] = useState(0);

    useEffect(() => {
      let start = 0;
      const interval = setInterval(() => {
        start += 1;
        if (start >= Math.round(score)) {
          clearInterval(interval);
        }
        setDisplayScore(start);
      }, 20);
      return () => clearInterval(interval);
    }, [score]);

    return (
      <Flex align="center">
        <CircularProgress
          value={displayScore}
          color={
            displayScore >= 70
              ? "green.400"
              : displayScore >= 50
              ? "yellow.400"
              : "red.400"
          }
          trackColor="#1e293b"
          size="80px"
          thickness="10px"
        >
          <CircularProgressLabel color="white" fontSize="lg" fontWeight="medium">
            {Math.round(displayScore)}
          </CircularProgressLabel>
        </CircularProgress>
        <Box ml={4}>
          <Text fontWeight="bold" fontSize="lg">
            {label}
          </Text>
        </Box>
      </Flex>
    );
  };

  if (!insights || Object.keys(insights).length === 0) {
    return (
      <Modal isOpen={isOpen} onClose={onClose} size="6xl" isCentered>
        <ModalOverlay />
        <ModalContent bg="#1e293b" color="gray.200" borderRadius="lg">
          <ModalHeader>
            <Flex justify="space-between" align="center">
              <Text fontSize="lg" fontWeight="bold">
                No Data Available
              </Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton color="gray.400" />
          <ModalBody>
            <Text fontSize="sm">
              No analysis data is available for the selected advisor type.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }

  const {
    overallInvestmentScore,
    financials,
    market,
    product,
    team,
    overallRiskScore,
    overallRiskAnalysis,
    recommendations = [],
    overallInvestmentCase,
  } = insights;

  const sections = [
    { name: "Overall", score: overallInvestmentScore, analysis: overallInvestmentCase },
    { name: "Financial", data: financials },
    { name: "Market", data: market },
    { name: "Product", data: product },
    { name: "Team", data: team },
    { name: "Risk", score: overallRiskScore, analysis: overallRiskAnalysis },
  ];

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl" isCentered>
      <ModalOverlay />
      <ModalContent bg="#1e293b" color="gray.200" borderRadius="lg">
        <ModalHeader>
          <Flex justify="space-between" align="center">
            <Text fontSize="2xl" fontWeight="bold">
              {advisorType === "angel"
                ? "Angel Investor Perspective"
                : advisorType === "vc"
                ? "Venture Capitalist Perspective"
                : "Market Analyst Perspective"}
            </Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton color="gray.400" />
        <ModalBody>
          {/* Section Scores + Analysis */}
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
            {sections.map((section, index) => (
              <Box
                key={index}
                bg="rgba(28, 35, 54)"
                borderRadius="lg"
                p={4}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <AnimatedCircularGauge
                  score={section.score || section.data?.score || 0}
                  label={section.name}
                />
                <Text mt={4} fontSize="sm" color="gray.300">
                  {section.analysis || section.data?.analysis || "No analysis available."}
                </Text>
              </Box>
            ))}
          </SimpleGrid>

          {/* Recommendations */}
          <Box mt={8}>
            <Text fontWeight="bold" fontSize="lg" mb={4}>
              Recommendations
            </Text>
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
              {recommendations.map((recommendation, index) => (
                <Box
                  key={index}
                  bg="rgba(28, 35, 54)"
                  borderRadius="lg"
                  p={4}
                  borderWidth={2}
                  borderColor={
                    index === 0 ? "yellow.400" : index === 1 ? "gray.500" : "orange.400"
                  }
                >
                  <Flex align="center" mb={2}>
                    <Text
                      fontSize="3xl"
                      fontWeight="bold"
                      color={
                        index === 0
                          ? "yellow.400"
                          : index === 1
                          ? "gray.500"
                          : "orange.400"
                      }
                      mr={2}
                    >
                      {index + 1}
                    </Text>
                  </Flex>
                  <Divider borderColor="gray.500" />
                  <Text mt={2} fontSize="sm" color="gray.300">
                    {recommendation}
                  </Text>
                </Box>
              ))}
            </SimpleGrid>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ViewAdvisorAnalysis;
