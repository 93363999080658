import React, { useState } from 'react';
import {
  IconButton,
  Tooltip,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Flex,
  Box,
  Divider,
  useToast,
  Portal,
} from '@chakra-ui/react';
import { HiOutlineUserGroup, HiOutlineFire, HiOutlineRocketLaunch, HiOutlineGlobeAmericas } from 'react-icons/hi2';
import { RepeatIcon, ViewIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { API_URL } from '../../../constants';
import ViewAdvisorAnalysis from '../shared/ViewAdvisorAnalysis';
import { keyframes } from '@emotion/react';

// Keyframes for spinning animation
const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const GenerateAdvisorAnalysis = ({ reportId, onUpdateReport }) => {
  const [loadingAdvisor, setLoadingAdvisor] = useState(null); // Tracks which advisor analysis is loading
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAdvisor, setSelectedAdvisor] = useState(null);
  const toast = useToast();

  // Fetch insights from localStorage for a specific advisor type
  const hasInsights = (advisorType) => {
    const advisorAnalysisData = JSON.parse(localStorage.getItem('advisorAnalysisData')) || [];
    return advisorAnalysisData.some((analysis) => analysis.advisorType === advisorType);
  };

  // Open the modal with relevant insights
  const openModal = (advisorType) => {
    const advisorAnalysisData = JSON.parse(localStorage.getItem('advisorAnalysisData')) || [];
    const advisorInsights = advisorAnalysisData.find((analysis) => analysis.advisorType === advisorType);
    setSelectedAdvisor(advisorInsights);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedAdvisor(null);
  };

  const handleGenerateAdvisorAnalysis = async (advisorType) => {
    setLoadingAdvisor(advisorType);
    const token = localStorage.getItem('token');
  
    try {
      const response = await axios.post(
        `${API_URL}/v2/reports/generate-advisor-analysis`,
        { reportId, advisorType },
        { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' } }
      );
  
      toast({
        title: `${advisorType} Insights generated successfully.`,
        description: response.data.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
  
      if (onUpdateReport) {
        await onUpdateReport(); // Update the report data
      }
  
      // Reload the page to reflect the updated analysis
      window.location.reload();
    } catch (error) {
      toast({
        title: `Error generating ${advisorType} insights.`,
        description: error.response?.data?.error || 'An unexpected error occurred.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoadingAdvisor(null);
    }
  };  

  const advisors = [
    {
      label: 'Angel Investor',
      value: 'angel',
      icon: HiOutlineFire,
      description: 'Focuses on early-stage growth and scalability.',
    },
    {
      label: 'VC Investor',
      value: 'vc',
      icon: HiOutlineRocketLaunch,
      description: 'Emphasizes market fit and revenue potential.',
    },
    {
      label: 'Market Analyst',
      value: 'analyst',
      icon: HiOutlineGlobeAmericas,
      description: 'Analyzes competitive positioning and risks.',
    },
  ];

  return (
    <>
      <Menu
        onOpen={() => setIsMenuOpen(true)}
        onClose={() => setIsMenuOpen(false)}
      >
        <Flex align="center">
          <Tooltip label="Generate Advisor Insights" aria-label="Generate advisor analysis tooltip">
            <MenuButton
              as={IconButton}
              icon={
                loadingAdvisor ? (
                  <RepeatIcon
                    sx={{
                      color: 'blue.400',
                      animation: `${spin} 1s linear infinite`,
                    }}
                  />
                ) : (
                  <HiOutlineUserGroup
                    sx={{
                      color: isMenuOpen ? 'white' : loadingAdvisor ? 'blue.400' : 'gray.400',
                    }}
                  />
                )
              }
              aria-label="Generate Advisor Analysis"
              variant="ghost"
              fontSize="lg"
              sx={{
                bg: isMenuOpen ? 'rgba(15, 23, 42, 0.9)' : 'transparent',
                color: isMenuOpen ? 'white' : loadingAdvisor ? 'blue.400' : 'gray.400',
                _hover: {
                  bg: isMenuOpen ? 'rgba(15, 23, 42, 0.9)' : 'rgba(34, 34, 34, 0.8)',
                  color: 'white',
                },
                _active: {
                  bg: 'rgba(15, 23, 42, 0.9)',
                  color: 'white',
                },
              }}
            />
          </Tooltip>
        </Flex>
        <Portal>
          <MenuList bg="rgba(15, 23, 42, 0.9)" borderColor="#475569" boxShadow="lg">
            <Flex align="center" justify="space-between" px={3} py={1}>
              <Text fontWeight="bold" color="gray.500" fontSize="xs" textAlign="left">
                Advisor Insights:
              </Text>
            </Flex>
            <Divider borderColor="gray.600" />

            {advisors.map((advisor, index) => (
              <React.Fragment key={index}>
                <MenuItem
                  isDisabled={!!loadingAdvisor}
                  bg="rgba(15, 23, 42, 0.9)"
                  fontWeight="bold"
                  color="#cbd5e1"
                  _hover={{ bg: '#334155', color: 'white' }}
                  py={4}
                  px={4}
                >
                  <Flex align="center" justify="space-between" width="100%">
                    <Flex align="center">
                      <Box as={advisor.icon} mr={3} fontSize="lg" sx={{ color: 'gray.400' }} />
                      <Box maxWidth="80%">
                        <Text fontWeight="semibold">{advisor.label}</Text>
                        <Text fontSize="xs" fontWeight="normal" color="gray.400" noOfLines={2}>
                          {advisor.description}
                        </Text>
                      </Box>
                    </Flex>
                    <Flex align="center" gap={2}>
                      <Tooltip label="Generate New Analysis" aria-label="Generate new analysis">
                        <IconButton
                          icon={<RepeatIcon />}
                          size="sm"
                          sx={{
                            bg: loadingAdvisor === advisor.value ? 'blue.400' : '#1e293b',
                            color: loadingAdvisor === advisor.value ? 'white' : 'gray.400',
                          }}
                          _hover={{
                            bg: 'rgba(24, 30, 40, 0.7)',
                            color: 'white',
                          }}
                          onClick={() => handleGenerateAdvisorAnalysis(advisor.value)}
                          isDisabled={!!loadingAdvisor}
                        />
                      </Tooltip>
                      <Tooltip label="View Analysis" aria-label="View analysis">
                        <IconButton
                          icon={<ViewIcon />}
                          size="sm"
                          sx={{
                            bg: '#1e293b',
                            color: hasInsights(advisor.value) ? 'white' : 'gray.600',
                          }}
                          _hover={
                            hasInsights(advisor.value)
                              ? { bg: 'rgba(24, 30, 40, 0.7)', color: 'white' }
                              : {}
                          }
                          onClick={() => hasInsights(advisor.value) && openModal(advisor.value)}
                          isDisabled={!hasInsights(advisor.value)}
                        />
                      </Tooltip>
                    </Flex>
                  </Flex>
                </MenuItem>
                {index < advisors.length - 1 && <Divider borderColor="gray.600" />}
              </React.Fragment>
            ))}
          </MenuList>
        </Portal>
      </Menu>

      {selectedAdvisor && (
        <ViewAdvisorAnalysis
          advisorType={selectedAdvisor?.advisorType}
          insights={selectedAdvisor}
          isOpen={isModalOpen}
          onClose={closeModal}
        />
      )}
    </>
  );
};

export default GenerateAdvisorAnalysis;
