import React, { useState } from 'react';
import { Box, VStack } from '@chakra-ui/react';
import InvestmentTopMetrics from './investment/InvestmentTopMetrics';
import DetailedChart from './shared/DetailedChart';

const InvestmentSection = ({ auditData, reportData }) => {
  const [showRevenue, setShowRevenue] = useState(true);
  const [showCost, setShowCost] = useState(true);
  const [showBurn, setShowBurn] = useState(true);
  const [showValuation, setShowValuation] = useState(false);
  const [showCustomers, setShowCustomers] = useState(false);
  const [showEmployees, setShowEmployees] = useState(false);

  const investmentDatasetsConfig = [
    {
      label: 'Revenue',
      dataKey: 'revenueHistory',
      valueKey: 'revenue',
      color: '75, 192, 192',
      yAxisID: 'y-axis-monetary',
      showToggle: showRevenue,
      setShowToggle: setShowRevenue,
      switchColorScheme: 'teal',
    },
    {
      label: 'Cost',
      dataKey: 'costHistory',
      valueKey: 'cost',
      color: '255, 206, 86',
      yAxisID: 'y-axis-monetary',
      showToggle: showCost,
      setShowToggle: setShowCost,
      switchColorScheme: 'yellow',
    },
    {
      label: 'Burn',
      dataKey: 'burnHistory',
      valueKey: 'burn',
      color: '255, 99, 132',
      yAxisID: 'y-axis-monetary',
      showToggle: showBurn,
      setShowToggle: setShowBurn,
      switchColorScheme: 'red',
    },
    {
      label: 'Valuation',
      dataKey: 'valuationHistory',
      valueKey: 'valuation',
      color: '153, 102, 255',
      yAxisID: 'y-axis-monetary',
      showToggle: showValuation,
      setShowToggle: setShowValuation,
      switchColorScheme: 'purple',
    },
    {
      label: 'Customers',
      dataKey: 'customerHistory',
      valueKey: 'customers',
      color: '54, 162, 235',
      yAxisID: 'y-axis-numbers',
      showToggle: showCustomers,
      setShowToggle: setShowCustomers,
      switchColorScheme: 'blue',
    },
    {
      label: 'Employees',
      dataKey: 'employeeHistory',
      valueKey: 'employees',
      color: '255, 159, 64',
      yAxisID: 'y-axis-numbers',
      showToggle: showEmployees,
      setShowToggle: setShowEmployees,
      switchColorScheme: 'orange',
    },
  ];

  return (
    <VStack spacing={0} align="stretch" mb={6}>
      {/* Top Metrics Section */}
      <Box>
        <InvestmentTopMetrics auditData={auditData} reportData={reportData} />
      </Box>

      {/* Growth Chart Section */}
      <Box>
        <DetailedChart
          datasetsConfig={investmentDatasetsConfig}
          reportData={{
            ...reportData,
            timeSeriesData: {
              ...reportData?.timeSeriesData,
              burnHistory: reportData?.timeSeriesData?.burnHistory?.map((entry) => ({
                ...entry,
                burn: Math.abs(entry.burn), // Convert burn values to absolute
              })),
            },
          }}
          foundingDate={auditData.foundingDate}
        />
      </Box>
    </VStack>
  );
};

export default InvestmentSection;
